@font-face {
  font-family: "Lexend",Sans-serif;
}

body {
  margin: 0;
  font-family: "Lexend",Sans-serif;
}

code {
  font-family: "Lexend",Sans-serif;
}
