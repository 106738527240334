@media (min-width: 768px) {
    html {
        background-size: cover;
    }

    .loginform {
        max-width: 750px;
        margin: auto;
    }

    header {
        height: 90px;
    }

    header img {
        max-width: 120px;
    }

    header button.refresh {
        top: 29px;
        right: 45px;
        width: 31px;
        height: 31px;
    }

    header a.account {
        top: 32px;
        right: 12px;
        width: 31px;
        height: 31px;
    }

    footer ul {
    }

    footer ul li {
    }

    footer ul li a {
        font-size: 14px;
        font-weight: bold;
    }

    footer ul li a span {
        width: 54px;
        height: 54px;
    }

    .LoadingDiv img {
        width: 25%;
    }

    /* CONTENT */
    .contentWrapper {
        font-size: 16px;
    }

    .content h1 {
        font-size: 30px;
    }

    .content h2 {
        font-size: 24px;
    }

    .content button.button {
        padding: 0 25px;
    }

    .content .navigation {
        height: auto;
    }

    .content .navigation button {
        padding: 0;
        width: 50px;
        height: 50px;
        font-size: 20px;
    }


    /* INSTALLATIES */
    .content .navigation .label h2 {
        margin-bottom: 5px;
    }

    .content .navigation .label h3.name {
        margin-bottom: 5px;
    }

    .content .navigation .label h3.detail {
        line-height: 22px;
    }

    .content .navigation div.left {
        top: 5px;
    }

    .content .navigation div.right {
        top: 5px;
    }

    #installatie_img {
        height: 300px;
    }


    /* OPBRENGST */
    .summary ul {
        font-size: 20px;
        line-height: 35px;
    }

    .summary ul label {
        width: 150px;
    }


    .chartDiv .timeframenavigation button {
        font-size: 16px;
    }


    /* DAKCHECK*/
    .dakcheck {
        line-height: 30px;
    }

    .dakcheck form .inputRow {
        height: 80px;
    }

    .dakcheck form .inputRow label {
        font-size: 16px;
    }

    .dakcheck form .inputRow label small {
        font-size: 16px;
    }

    .dakcheck form .inputRow input {
        width: 50px;
        height: 50px;
        background: none;
        font-size: 16px;
    }

    .dakcheck form.step2 .inputRow {
        height: 150px;
    }

    button.backlink {
        font-size: 16px;
    }

}
