html, body, .wrapper, main, main > div {
    margin: 0;
    padding: 0 2px;
    font-family: "Lexend",Sans-serif;
}

html {
    background:  #eff4f3;
    background-size: auto;
    width: calc(100% - 5px);
    min-height: 100%;
    height: auto;
}

#root {

}

/*START: Common CSS*/
.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.font-bold {
    font-weight: bold !important;
}

/*END: Common CSS*/

.loginscreen {
    text-align: center;
}

.loginscreen .logo {
    text-align: center;
    padding: 70px 0;
}

.loginscreen .logo img {
    width: 65%;
    max-width: 450px;
}

.loginscreen .loadinghome {
    color: #000;
}

.loginscreen div.error {
    color: #000;
    font-weight: bold;
    margin: 0 auto 20px auto;
    font-size: 13px;
}

.loginscreen .loginform {
    background: #fff;
    border-radius: 20px 40px 10px 10px;
    padding: 10px;
    margin: auto;
}

.loginscreen .loginform input {
    width: 90%;
    margin-bottom: 20px;
    border-radius: 10px;
    height: 55px;
    border: 2px solid #000;
    background: #fff;
    padding: 0% 5%;
    outline: none;
    font-size: 16px;
    font-family: "Lexend",Sans-serif;
    box-sizing: border-box;
}

.loginscreen .loginform input.error {
    border: 1px solid #f00;
    font-weight: normal;
}

.loginscreen .loginform button {
    display: block;
    background: #FFC72C;
    background-image: linear-gradient(to right,#FFC24B, #FDD30F);
    width: 90%;
    line-height: 55px;
    border-radius: 30px;
    margin: auto;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    outline: none;
    border: none;
    font-family: "Lexend",Sans-serif;
    font-weight: bold;
    margin-bottom: 20px;
}

.loginscreen .meta {
    margin-top: 60px;

}

.loginscreen .meta a {
    color: #000;
}

.loginscreen .meta span.version {
    display: none;
}


/* FOR NEXT PAGES */

header {
    text-align: center;
    height: 60px;
}

.headerWrapper {
    color: #ffffff;
    text-align: center;
}

.headerWrapper h1:after {
    margin-left: 10px;
    background-image: url('../images/icon_refresh.png');
    background-size: 24px 25px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
    content: "";
}

header img {
    width: 20%;
    max-width: 100px;
    margin-top: 18px;
}

header a.account {
    position: absolute;
    float: right;
    top: 20px;
    right: 10px;
    border: none;
    width: 21px;
    height: 21px;
    background: url('../images/account.png');
    background-size: contain;
    background-repeat: no-repeat;
    text-indent: -9999px;
    outline: none;
}

header a.account .unread {
    display: block;
    background: #f00;
    border-radius: 14px;
    height: 8px;
    width: 8px;
    margin-left: 13px;
    margin-top: -18px;
}

header button.refresh {
    position: fixed;
    float: right;
    top: 20px;
    right: 35px;
    border: none;
    width: 21px;
    height: 21px;
    background-size: contain;
    background: url('../images/refresh.png') no-repeat;
    text-indent: -9999px;
    outline: none;
}

.LoadingDiv {
    text-align: center;
    font-size: 16px;
    border-radius: 5px;
}

.LoadingDiv img {
    width: 50%;
}

.contentWrapper {
    min-height: calc(100vh - 215px);
    margin-bottom: 125px;
    background: #fff;
    font-size: 13px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-radius: 20px;
}

.content {
    padding: 10px;
}

.content h1 {
    text-align: center;
    font-size: 20px;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
}

.content h1:after {
    content: "";
    position: absolute;
    left: 40%;
    bottom: -15px;
    height: 3px;
    width: 20%; /* or 100px */
    border-bottom: 3px solid #FFC72C;
}

.content h2 {
    font-size: 16px;
    margin: 20px 0;
}

.content button.button {
    display: block;
    background: #FFC72C;
    background-image: linear-gradient(to right,#FFC24B, #FDD30F);
    line-height: 30px;
    padding: 0 15px;
    border-radius: 100px;
    margin: 20px auto;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    outline: none;
    border: none;
    z-index: 1;
    position: relative;
}

.content button.button2 {
    display: block;
    background: #2c6dbe;
    line-height: 30px;
    padding: 0 15px;
    border-radius: 9px;
    margin: 20px auto;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    outline: none;
    border: none;
}

.contentWrapper .content {
    padding: 10px 40px;
    min-height: calc(100vh - 250px);
}

.contentWrapper.installaties .content {
    text-align: center;
    padding: 0 40px;
}

.contentWrapper.installaties .content .image {
    position: relative;
    margin: 0 -40px;
}

.contentWrapper.installaties .content .image .buttons {
    position: absolute;
    right: 0;
    bottom: -5px;
    z-index: 99;
    width: 50px;
}
.contentWrapper.installaties .installatiegarantie {
    font-size: 13px;
    margin-top: 20px;
}
.contentWrapper.installaties .installatiegarantie h2 {
    margin: 20px 0
}

.contentWrapper.installaties .content h3.title {
    font-weight: bold;
    line-height: 1.5;
}

.contentWrapper.dakcheck .content {
    padding: 10px 40px;
    min-height: calc(100vh - 205px);
}

.pdfcontainer .container {
    border: 1px solid #000;
    box-shadow: 2px 2px;
    clear: both;
}

.pdfcontainer .closebutton {
    float: right;
    font-size: 20px;
    line-height: 60px;
    font-weight: bold;
}

.pdfcontainer h2 {
    float: left;
}

footer {
    position: fixed;
    display: inline-block;
    width: calc(100% - 20px);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 1;
    margin: 10px 0;
    padding: 0;
    border: 0;
}

footer ul {
    margin: auto;
    padding: 0;
    width: 100%;
    float: left;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    grid-auto-rows: 100px;
    background: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-radius: 20px;
}

footer li {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    /*margin-right: calc((100% - 180px) / 3);*/
    float: left;
}

footer li:last-child {
    margin-right: 0;
}

footer ul li a {
    display: initial;
    color: #444;
    font-size: 14px;
    font-weight: bolder;
    text-decoration: none;
    border-radius: 5px;

}

footer li a.dakcheck {
    line-height: normal;
}

footer li a span {
    display: block;
    width: 54px;
    height: 54px;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    margin: auto auto 5px;
    filter: grayscale(100%);
}

footer li a.installaties span {
    background: url('../images/icon_installaties.png');
}

footer li a.opbrengst span {
    background: url('../images/icon_opbrengst.png');
}

footer li a.dakcheck span {
    background: url('../images/icon_dakcheck.png');
}

footer li a.vrienden span {
    background: url('../images/icon_vrienden.png');
}


footer li a.active {
    color: #3D87CF;
}

footer li a.active span {
    filter: none;
}


/* OPBRENGST PAGE */

.summary ul {
    margin: 0 0 15px 0;
    padding: 0 0 15px 0;
    list-style: none;
    font-size: 13px;
    text-align: left;
    font-weight: bold;
    line-height: 25px;
    border-bottom: 3px solid #FFC72C;
}

.summary ul label {
    display: inline-block;
    width: 90px;
    font-weight: normal;
}

.chartDiv {
    text-align: center;
}

.chartDiv .timeframenavigation {
    height: 50px;
}

.chartDiv .timeframenavigation ul {
    padding: 0;
}

.chartDiv .timeframenavigation ul li {
    display: inline-block;
    border-right: 1px solid #373737;
    box-sizing: border-box;
    padding: 0;
    width: 25%;
    float: left;
    font-size: 10px;
}

.chartDiv .timeframenavigation ul li:nth-child(4) {

    border: none;
}

.chartDiv .timeframenavigation button {
    display: inline-block;
    background: none;
    line-height: 25px;
    border-radius: 30px;
    color: #373737;
    text-decoration: none;
    text-transform: uppercase;
    outline: none;
    border: none;
    padding: 0 10px;
    font-size: 11px;
}

.chartDiv .timeframenavigation button.active {
    background: #FFC72C;
}

/* INSTALLATIES PAGE*/

img {
    max-width: 100%;
}

#image {
    display: none;
}

#installatie_img {
    height: 200px;
    border-radius: 20px;
}

.buttons .button {
    text-align: center;
    margin: auto;
    border: none;
}

.buttons .button img {
    cursor: pointer;
}

.content .navigation {
    height: auto;
    position: relative;
}

.content .navigation .label {
    display: inline-block;
    width: 80%;
    margin: auto;
    text-align: center;
    line-height: 32px;
    font-weight: bold;
}

.content .navigation .label h2 {
    margin: 0;
}

.content .navigation .label h3.name {
    margin-bottom: 0;
}

.content .navigation .label h3.detail {
    line-height: 1.5;
}

.content .navigation .label p {
    margin: 0;
    line-height: 16px;
}

.content .navigation div.left {
    position: absolute;
    top: 15px;
    left: 0;
}

.content .navigation div.right {
    position: absolute;
    top: 15px;
    right: 0;
}

.content .navigation button {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
}

label.button {
    display: inline-block;
    background: #2C6DBE;
    line-height: 24px;
    padding: 0 15px;
    border-radius: 30px;
    margin: 20px auto;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    font-size: 10px;
    outline: none;
    border: none;
}

ul {
    list-style: none;
    padding: 0;
}

.content ul.hidedisc {
    list-style: none;
    margin: auto;
}

ul li a {
    color: #2C6DBE;
}

.installatie {
    border-bottom: 2px solid #FFC72C;
    margin: 20px 0;
    padding-bottom: 20px;
}

.installatie h2 {
    margin-bottom: 5px;
}

.installatie p {
    margin-top: 0px;
    line-height: 20px;
}

.faq ul {
    line-height: 24px;
    list-style: inside;
}

.faq ul li button {
    background: none;
    border: none;
    color: #2C6DBE;
    cursor: pointer;
    padding: 0;
    font-family: "Lexend",Sans-serif;
}

/* DAKCHECK PAGE */

.dakcheck {
    line-height: 24px;
}

button.backlink {
    text-decoration: none;
    display: block;
    margin: auto;
    color: #2C6DBE;
    background: none;
    border: none;
    outline: none;
}

.dakcheck form .inputRow {
    display: inline-block;
    width: 100%;
    height: 60px;
}

.dakcheck form .inputRow .clear {
    clear: both;
}

.dakcheck form .inputRow label {
    display: inline-block;
    width: 50%;
    font-weight: bold;
    font-size: 13px;
}

.dakcheck form .inputRow label small {
    font-weight: normal;
    font-size: 13px;
}

.dakcheck form .inputRow img {
    float: right;
    width: 20%;
}

.dakcheck form .inputRow .inputField {
    float: right;
    margin-right: 5%;
    border-radius: 2px;
}

.dakcheck form .inputRow input {
    width: 34px;
    height: 30px;
    background: none;
    border: 1px solid #363636;
    text-align: center;
}

.dakcheck form .inputRow input.error {
    border: 1px solid #c00;
}

.dakcheck form.step2 .inputRow {
    height: 80px;
}

.dakcheck form.step2 .inputRow .inputField {
    float: left;
}

.dakcheck form.step3 .inputRow {
    padding-bottom: 30px;
    height: auto;
}

.dakcheck form.step3 .inputRow .inputField {
    float: none;
    margin: 0;
}

.dakcheck form.step3 img {
    width: 30%;
    margin: 50px auto;
    display: block;
}

.dakcheck form.step3 select {
    display: block;
    margin: auto;
    text-align: center;
    float: none;
    padding: 10px 20px;
    border: 1px solid #363636;
    border-radius: 2px;
}

.dakcheck .stepBerekening {
    text-align: center;
}

.dakcheck .stepVriend .inputRow {
    height: 80px;
}

.dakcheck .stepVriend .inputRow .inputField {
    width: 100%;
    float: none;
}

.dakcheck .stepVriend .inputRow .inputField input {
    box-sizing: border-box;
    width: 100%;
    height: 35px;
    border-radius: 2px;
    padding: 10px;
    text-align: left;
}

/* VRIENDEN PAGE */

.accordion {
    margin: 0 auto 15px;
}

.accordionHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 15px;
    background: #2c6dbe;
    color: #fff;
    border-radius: 9px;
    font-weight: bold;
    transition: 0.14s;
}

.accordionHeader:hover {
    background: #2c6dbe;
    cursor: pointer;
}

.accordionHeader svg {
    position: absolute;
    right: 50px;
    width: 24px;
    height: 24px;
    fill: #e0e0e0;
}

.accordionContent {
    visibility: hidden;
    background: #fff;
    padding: 0 16px;
    opacity: 0;
    height: 0;
    transition: visibility 0s, opacity .15s,
    padding .25s,
    height .25s;
}

.accordionContent.active {
    visibility: visible;
    padding: 16px;
    opacity: 1;
    height: auto;
    transition: opacity .15s,
    padding .25s,
    height .25s;
}

.lead {
    margin-bottom: 20px;
    /*padding-bottom: 20px;*/
    /*border-bottom:3px solid #FFC72C;*/
}

.lead .leadname {
    font-weight: bold;
}

.lead .leadname .aanhef {
    text-transform: capitalize;
}

/* ACCOUNT PAGE */

.changePassword {
    line-height: 20px;
}

.changePassword strong {
    display: block;
    width: 70%;
    float: left;
}

.changePassword form .inputRow {
    display: inline-block;
    width: 100%;
    height: 60px;
}

.changePassword form .inputRow .clear {
    clear: both;
}

.changePassword form .inputRow label {
    display: inline-block;
    width: 100%;
    font-weight: bold;
    font-size: 13px;
}

.changePassword form .inputRow .inputField {

    border-radius: 2px;
}

.changePassword form .inputRow input {
    width: 98%;
    max-width: 400px;
    height: 30px;
    background: none;
    border: 1px solid #363636;
    padding-left: 1%;
}

.changePassword form .inputRow input.error {
    border: 1px solid #c00;
}

.changePassword div.error {
    color: #c00;
    margin-bottom: 15px;
    font-weight: bold;
}

.content div.success {
    color: #0A0;
    margin-bottom: 15px;
    font-weight: bold;
}

.notice {
    background: #eee;
    margin: 10px;
    border: 1px solid #444;
    padding: 20px;
    text-align: center;
}

.notice button.button {
    margin: auto;
}

.notice.hide {
    display: none;
}
